// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // let lastScrollTop = 0;
  // let headerTop = $('.header-top');
  // let header = $('.header');
  // let headerTopHeight = headerTop.outerHeight();
  //
  // function checkScroll() {
  //   let scrollTop = $(window).scrollTop();
  //   console.log(scrollTop);
  //   console.log(lastScrollTop);
  //   if (scrollTop > lastScrollTop) {
  //     if (headerTop.is(':visible')) {
  //       headerTop.stop().slideUp(300);
  //       header.css({
  //         top: '0',
  //         position: 'fixed',
  //       });
  //       console.log('hidden');
  //     }
  //   } else {
  //     if (scrollTop > 50 && headerTop.is(':hidden')) {
  //       headerTop.stop().slideDown(300, function () {
  //         header.css('top', headerTopHeight + 'px');
  //         console.log('visible');
  //       });
  //     }
  //   }
  //
  //   lastScrollTop = scrollTop;
  // }
  //
  // $(window).on('scroll', checkScroll);

  let lastScrollTop = 0;
  let headerTop = $('.header-top');
  let header = $('.header');
  let headerTopHeight = headerTop.outerHeight();
  let isHeaderTopVisible = true;

  function checkScroll() {
    let scrollTop = $(window).scrollTop();
    if (scrollTop <= 20) {
      return;
    }
    if (scrollTop > lastScrollTop) {
      if (isHeaderTopVisible) {
        headerTop.stop(true, true).slideUp(200);
        header.css({
          top: '0',
        });
        isHeaderTopVisible = false;
      }
    } else {
      if (!isHeaderTopVisible && scrollTop > 49) {
        headerTop.stop(true, true).slideDown(200, function () {
          header.css({
            top: headerTopHeight + 'px',
          });
        });
        isHeaderTopVisible = true;
      }
    }

    // if (scrollTop <= 20) {
    //   headerTop.stop(true, true).slideDown(200);
    //   header.css({
    //     top: headerTopHeight + 'px',
    //   });
    //   isHeaderTopVisible = true;
    //   console.log('show');
    // }

    lastScrollTop = scrollTop;
  }

  $(window).on('scroll touchmove', checkScroll);

  //Results slider
  $('.results__slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  //Faqs slider
  $('.js-faqs__slider').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  // team-slider
  $('.js-team-testimonial-slider').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // resorces slider
  $(
    '.car-accidents-js, .truck-accidents-js, .general-js, .insurance-claims-js'
  ).slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  var page = 1;

  // load more cases
  $('#load-more').on('click', function () {
    var button = $(this);
    let offset = $('.cases-list__item').length;
    console.log(offset);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more_cases',
        nonce: ajax_object.ajax_url.nonce,
        offset: offset,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        $('.ajax-posts').append(response.html);
        button.text('View More');

        if (!response.has_more) {
          button.hide();
        }
      },
      error: function () {
        button.text('View More');
        console.log('err');
      },
    });
  });

  // blog-posts load more
  $('#load-more-blog').on('click', function () {
    var button = $(this);
    let offset = $('.posts-list-item').length;
    console.log(offset);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more_posts',
        nonce: ajax_object.ajax_url.nonce,
        offset: offset,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        $('.js-post-list').append(response.html);
        button.text('View More');

        if (!response.has_more) {
          button.hide();
        }
      },
      error: function () {
        button.text('View More');
        console.log('err');
      },
    });
  });

  $('#load-more-docs').on('click', function () {
    var button = $(this);
    let offset = $('.documents-list__item').length;
    let tax = $('.ajax-cat-list').attr('data-cat-tax');
    let slug = $('.ajax-cat-list').attr('data-slug');

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more_docs',
        nonce: ajax_object.ajax_url.nonce,
        offset: offset,
        tax: tax,
        slug: slug,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        $('.ajax-cat-list').append(response.html);
        button.text('View More');

        if (!response.has_more) {
          button.hide();
        }
      },
      error: function () {
        button.text('View More');
        console.log('err');
      },
    });
  });

  /**
   * Video Filtration
   */
  let postPerPage = 9;

  function loadScrollPosts(response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.video-item').length;
    let category = $('.view-wrapper').find('.active').data('category');
    let name = $('.view-wrapper').find('.active').data('name');
    let postType = $('.post-load').data('post-type');

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        postType: postType,
        action: 'loadmore',
        offset: offset,
        postperpage: postPerPage,
        category: category,
        name: name,
      },
      beforeSend: function () {
        $('.ajax-loading').hide();
      },
      success: function (response) {
        $('.ajax-loading').hide();
        if (taxonomy) {
          $('.ajax-posts').html(response.html);
          $('.show-cat-btn__text').text(name);
          // $('.post-list__category').removeClass('show-cat');
          // $('.reset').css('display', 'inline-block');
          $('.show-cat-btn').removeClass('active');
        } else {
          $('.ajax-posts').append(response.html);
        }
        if ($('.video-item').length < response.post_count) {
          $('.ajax-loading').show();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.cat-name').click(function (e) {
    e.preventDefault();
    $('.cat-name').removeClass('active');
    $(this).addClass('active');
    $('.post-list__category_wrapper').toggleClass('show');
    $('.category-wrapper').toggleClass('active');
    loadScrollPosts('response', true);
  });
  $('.post-load').click(function () {
    loadScrollPosts();
  });

  $(document).on('click', '.show-cat-btn', function () {
    $(this).toggleClass('is-active');
    $('.post-list__category_wrapper').toggleClass('show');
    $('.category-wrapper').toggleClass('active');
  });
  $(document).on('click', '.reset', function () {
    $('.category-wrapper').removeClass('active');
    $('.post-list__category_wrapper').removeClass('show');
    $('.show-cat-btn__text').text('View All');
  });

  /**
   * FAQ Cat Load More
   */
  let catPerPage = 9;

  function loadScrollCats(postType, response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.post__wrapper').length;
    let cat = $('.post-load').data('cat');
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        postType: postType,
        action: 'loadmore_cats',
        offset: offset,
        postperpage: catPerPage,
        category: cat,
      },
      beforeSend: function () {
        $('.ajax-loading').hide();
      },
      success: function (response) {
        $('.ajax-loading').hide();
        $('.ajax-cat-list').append(response.html);
        // initReadMore();
        $('.matchHeight').matchHeight();
        // Check if all posts have been loaded
        if ($('.post__wrapper').length < response.post_count) {
          $('.ajax-loading').show(); // Show loading indicator for more posts
        } else {
          $('.post-load').hide(); // Hide the load more button if no more posts
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.post-load').click(function () {
    let postType = $(this).data('post-type');
    loadScrollCats(postType);
  });

  function updateSubMenuPositions() {
    if ($(window).width() >= 1025 && $('.practice-menu-section').length) {
      let $practiceMenu = $('.practice-menu').first();

      if ($practiceMenu.length) {
        let practiceMenuTop = $practiceMenu.offset().top;

        $('.practice-menu li > ul').each(function () {
          let $subMenu = $(this);
          let $parentLi = $subMenu.parent('li');

          let parentLiTop = $parentLi.offset().top;

          let newTop = parentLiTop - practiceMenuTop;

          $subMenu.css({
            top: `-${newTop}px`,
          });
        });
      }
    }
  }

  updateSubMenuPositions();
  $(window).on('resize', updateSubMenuPositions);
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.97,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );
  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('body').toggleClass('no-scroll');
      $('#main-menu').toggleClass('active');
      $('#icw').toggleClass('up');
      $('#icw--call--content').toggleClass('up');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('body').removeClass('no-scroll');
      $('#main-menu').removeClass('active');
      $('#icw').removeClass('up');
      $('#icw--call--content').removeClass('up');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  $(document).on('click', '.download-button', function () {
    const fileUrl = $(this).data('file-url');
    const formContainer = $($(this).attr('href'));
    formContainer.find('.hidden-file-url').val(fileUrl);
  });

  $(document).on('gform_confirmation_loaded', function (event, formId) {
    if (formId === 3) {
      const formContainer = $('.fancybox-container');
      const fileUrl = $('.hidden-file-url').val();
      if (fileUrl) {
        window.location.href = fileUrl;
      }
    }
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
